import { EmailOutlined } from "@mui/icons-material";
import { Box, Button, Stack } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link } from "react-router-dom";
import Flag from "../../../assets/Flag.gif";
import Gov from "../../../assets/Gov.png";
import Logo from "../../../assets/Logo.png";
import { flexCenter, flexDisplay } from "../../commonStyles";
import ScrollingText from "./ScrollingText";

const Navbar = () => {
  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "#fff" }}>
        <Box sx={{ flexGrow: 1, p: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={4} md={9}>
              <Box sx={{ ...flexDisplay, mt: 2 }}>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <Box
                    sx={{
                      "@media only screen and (max-width: 600px)": {
                        textAlign: "center",
                      },
                      mx: 2,
                    }}
                  >
                    <Box
                      component="img"
                      src={Gov}
                      sx={{
                        textAlign: "center",
                        height: "5.5rem",
                        marginLeft: { xs: 2, md: 0 },
                      }}
                    />
                  </Box>
                </Link>
                <Box
                  sx={{
                    textAlign: "justify",
                    "@media only screen and (max-width: 600px)": {
                      textAlign: "center",
                    },
                  }}
                >
                  <Typography
                    component="h6"
                    variant="h5"
                    sx={{
                      fontWeight: "bold",
                      color: "primary.dark",
                      marginLeft: { xs: 2, md: 0 },
                      "&:hover": {
                        color: "secondary.main",
                      },
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}
                  >
                    पाँचपोखरी थाङ्पाल गाउँपालिका
                  </Typography>
                  <Typography
                    variant="h6"
                    component="h3"
                    color="primary.main"
                    fontWeight="bold"
                    sx={{
                      textAlign: "justify",
                      marginLeft: { xs: 2, md: 0 },
                      "@media only screen and (max-width: 600px)": {
                        textAlign: "center",
                      },
                    }}
                  >
                    थाङ्पाल, सिन्धुपाल्चोक, बागमती प्रदेश, नेपाल
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="secondary.light"
                    fontWeight="bold"
                    sx={{
                      marginLeft: { xs: 2, md: 0 },
                    }}
                  >
                    "शिक्षा, स्वास्थ्य, कृषि, पर्यटन : समृद्ध पाँचपोखरीको मुलधन"
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Box>
                <Stack direction="row" sx={{ ...flexCenter }}>
                  {/* <Link to="/login" style={{ textDecoration: "none" }}>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "15px",
                      }}
                    >
                      <PersonOutlineOutlined />
                      <Typography
                        variant="subtitle1"
                        color="white"
                        fontWeight="bold"
                      >
                        कर्मचारी लगइन
                      </Typography>
                    </Button>
                  </Link> */}

                  <Link to="" style={{ textDecoration: "none" }}>
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "15px",
                        ml: 1,
                      }}
                    >
                      <EmailOutlined />
                      <Typography
                        variant="subtitle1"
                        color="white"
                        fontWeight="bold"
                      >
                        गुनासो
                      </Typography>
                    </Button>
                  </Link>
                </Stack>
                <Box sx={{ ...flexCenter, mt: 2 }}>
                  <Box component="img" height="4.5rem" src={Flag} />
                  <Box component="img" height="4.5rem" src={Logo} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ background: "#1976d2" }}>
          <ScrollingText />
        </Box>
      </AppBar>
    </>
  );
};

export default Navbar;
